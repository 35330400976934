import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../modules/Auth/login";
// import Register from "../modules/Auth/register";
import Dashboard from "../modules/Dashboard";
import CategoryList from "../modules/Category/CategoryList";
import CategoryAdd from "../modules/Category/CategoryAddNew";
import CategoryEdit from "../modules/Category/CategoryEditNew";
// import SubCategoryList from "../modules/SubCategory/SubCategoryList";
import BlogList from "../modules/Blog/BlogList";
import BlogAdd from "../modules/Blog/BlogAddNew";
import BlogEdit from "../modules/Blog/BlogEditNew";
import MarketAreaList from "../modules/MarketArea/MarketAreaList";
import GalleryList from "../modules/Gallery/GalleryList";
import GalleryAdd from "../modules/Gallery/GalleryAddNew";
import GalleryEdit from "../modules/Gallery/GalleryEditNew";
// import SettingList from "../modules/Settings/SettingList";
import TestimonialList from "../modules/Testimonial/TestimonialList";
import TestimonialAdd from "../modules/Testimonial/TestimonialAddNew";
import TestimonialEdit from "../modules/Testimonial/TestimonialEditNew";
import TeamList from "../modules/Team/TeamList";
import TeamAdd from "../modules/Team/TeamAddNew";
import TeamEdit from "../modules/Team/TeamEditNew";
import InquiryList from "../modules/Inquiry/InquiryList";
import ProductList from "../modules/Product/ProductList";
import ProductAdd from "../modules/Product/ProductAddNew";
import ProductEdit from "../modules/Product/ProductEditNew";

import General from "../modules/Pages/GeneralList";
import HomePage from "../modules/Pages/HomePage/HomePageList";
import AboutUs from "../modules/Pages/HomePage/AboutUsList";
import PrivacyPolicy from "../modules/Pages/HomePage/PrivacyPolicyList";
import TermsAndConditions from "../modules/Pages/HomePage/TermsAndConditionsList";
import SeoConfiguration from "../modules/Pages/SeoConfigurationList";

import CommonLayout from "../layouts";
import { AuthProvider } from "../context/AuthContext";
import { LoaderProvider } from "../context/LoaderContext";
import Loader from "../components/loader";
import PrivateRouteLayout from './PrivateRouteLayout'; 

const AppRoutes = () => (
  <AuthProvider>
    <LoaderProvider>
      <Loader />
      <Router>
        <Routes>
          <Route exact path="/login" element={<Login />} />
          {/* <Route exact path="/register" element={<Register />} /> */}
            <Route path="/" element={<PrivateRouteLayout />}>
              <Route path="/" element={<CommonLayout />}>
                <Route exact path="/" element={<Dashboard />} />
                <Route exact path="/blogs" element={<BlogList />} />
                <Route exact path="/blog-add" element={<BlogAdd />} />
                <Route exact path="/blog-edit/:id" element={<BlogEdit />} />
                {/* <Route exact path="/blog-add" element={<BlogList />} /> */}
                <Route exact path="/target-regions" element={<MarketAreaList />} />
                <Route exact path="/galleries" element={<GalleryList />} />
                <Route exact path="/gallery-add" element={<GalleryAdd />} />
                <Route exact path="/gallery-edit/:id" element={<GalleryEdit />} />
                {/* <Route exact path="/settings" element={<SettingList />} /> */}
                <Route exact path="/categories" element={<CategoryList />} />
                <Route exact path="/category-add" element={<CategoryAdd />} />
                <Route exact path="/category-edit/:id" element={<CategoryEdit />} />
                {/* <Route exact path="/sub-categories" element={<SubCategoryList />} /> */}
                <Route exact path="/testimonials" element={<TestimonialList />} />
                <Route exact path="/testimonial-add" element={<TestimonialAdd />} />
                <Route exact path="/testimonial-edit/:id" element={<TestimonialEdit />} />
                <Route exact path="/teams" element={<TeamList />} />
                <Route exact path="/team-add" element={<TeamAdd />} />
                <Route exact path="/team-edit/:id" element={<TeamEdit />} />
                <Route exact path="/inquiries" element={<InquiryList />} />
                <Route exact path="/products" element={<ProductList />} />
                <Route exact path="/product-add" element={<ProductAdd />} />
                <Route exact path="/product-edit/:id" element={<ProductEdit />} />

                <Route exact path="/general" element={<General />} />
                <Route exact path="/home-page" element={<HomePage />} />
                <Route exact path="/about-us" element={<AboutUs />} />
                <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route exact path="/terms-conditions" element={<TermsAndConditions />} />
                <Route exact path="/seo-configuration" element={<SeoConfiguration />} />
              </Route>
          </Route>
        </Routes>
      </Router>
    </LoaderProvider>
  </AuthProvider>
);

export default AppRoutes;
