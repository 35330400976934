import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSettings, updateSocialLinks, updateBasicInformation, 
  // updateCompanyInformation, updateWebsiteConfiguration, getCountries, getStates, getCities, 
  getLegalFirms, updateIcons, updateGoogleTag, updateCustomerCareData } from '../Settings/settingSlice';
import { getLocations, addLocation, updateLocation, makePrimaryStatus } from './locationSlice';

import { Container, Card, Row, Col, Toast, Modal, Button } from 'react-bootstrap';
import Input from "../../components/input";
import Textarea from '../../components/commonTextArea';
import FileInput from "../../components/fileInput";
import CommonTable from '../../components/commonTable';
import { Formik } from 'formik';
import CommonFormLabel from "../../components/formLabel";
import SimpleDropdown from "../../components/simpleDropdown";
import { basicInformationSchema, socialLinksSchema, iconsSchema, googleTagSchema,
  //  companyInformationSchema, 
  //  websiteConfigurationSchema, 
   customerCareSchema, locationSchema, locationUpdateSchema } from '../../schema/validationSchemas';
import ValidationFeedback from "../../components/validationFeedback";
import { TaskEdit02Icon } from '../../assets/icons';
import CommonButton from '../../components/commonButton';
import { useLoader } from '../../context/LoaderContext';

const SettingList = () => {
  const dispatch = useDispatch();
  const { locations } = useSelector((state) => state.locations);
  // console.log("locations", locations.data.length);
  const { setLoading } = useLoader();
  const { settings } = useSelector((state) => state.settings);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarVariant, setSnackbarVariant] = useState('success');
  const [editingSection, setEditingSection] = useState(null);
  // const [editingSubSection, setEditingSubSection] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [countriesOptions, setCountriesOptions] = useState([]);
  const [legalFirmsOptions, setLegalFirmsOptions] = useState([]);
  // const [statesOptions, setStatesOptions] = useState([]);
  // const [citiesOptions, setCitiesOptions] = useState([]);
  // const [selectedCountryId, setSelectedCountryId] = useState(null);
  // const [selectedStateId, setSelectedStateId] = useState(null);
  const [selectedLocationId, setSelectedLocationId] = useState('');
  const [selectedLocationData, setSelectedLocationData] = useState({});
  const [showStatusModal, setShowStatusModal] = useState(false);

  const locationColumns = [
    { label: 'Branch Name', key: 'branch_name' },
    { label: 'Email', key: 'email' },
    { label: 'Alt Email', key: 'alt_email' },
    { label: 'Phone Number', key: 'phone_number' },
    { label: 'Alt Phone Number', key: 'alt_phone_number' },
    { label: 'Whatsapp Number', key: 'whatsapp_number' },
    // { label: 'created at', key: 'created_at', type: 'date' },
    // {label: 'Status', key: 'is_active', type: 'status' },
    {label: 'Is Primary', key: 'is_primary', type: 'primary' },
  ];

  const fetchLegalFirms = useCallback(async () => {
    try {
      await dispatch(getLegalFirms({})).then((res) => {
        if (res.payload) {
          if (res.payload.length > 0) {
            const formattedData = res.payload.map((legalFirm) => ({
              value: legalFirm.id,
              label: legalFirm.name,
            }));
            setLegalFirmsOptions(formattedData);
          }
        } else {
          setLegalFirmsOptions([]);
        }
      });
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }, [dispatch]);

  // const fetchCountries = useCallback(async () => {
  //   try {
  //     await dispatch(getCountries({})).then((res) => {
  //       if (res.payload) {
  //         if (res.payload.length > 0) {
  //           const formattedData = res.payload.map((country) => ({
  //             value: country.id,
  //             label: country.name,
  //           }));
  //           setCountriesOptions(formattedData);
  //         }
  //       } else {
  //         setCountriesOptions([]);
  //       }
  //     });
  //   } catch (error) {
  //     console.error("Error fetching categories:", error);
  //   }
  // }, [dispatch]);

  // const fetchStates = useCallback(async () => {
  //   try {
  //     const payload = {
  //       country_id: selectedCountryId,
  //     };
  //     await dispatch(getStates({payload})).then((res) => {
  //       console.log("states", res);
  //       if (res.payload) {
  //         if (res.payload.length > 0) {
  //           const formattedData = res.payload.map((state) => ({
  //             value: state.id,
  //             label: state.name,
  //           }));
  //           setStatesOptions(formattedData);
  //         }
  //       } else {
  //         setStatesOptions([]);
  //       }
  //     });
  //   } catch (error) {
  //     console.error("Error fetching categories:", error);
  //   }
  // }, [dispatch, selectedCountryId]);

  // const fetchCities = useCallback(async () => {
  //   try {
  //     const payload = {
  //       state_id: selectedStateId,
  //     };
  //     await dispatch(getCities({payload})).then((res) => {
  //       console.log("cities", res);
  //       if (res.payload) {
  //         if (res.payload.length > 0) {
  //           const formattedData = res.payload.map((city) => ({
  //             value: city.id,
  //             label: city.name,
  //           }));
  //           setCitiesOptions(formattedData);
  //         }
  //       } else {
  //         setCitiesOptions([]);
  //       }
  //     });
  //   } catch (error) {
  //     console.error("Error fetching categories:", error);
  //   }
  // }, [dispatch, selectedStateId]);

  useEffect(() => {
    const fetchLocations = async () => {
      setLoading(true);
      const payload = {
        // search: searchTerm,
      };
  
      try {
        await dispatch(getLocations({ payload }));
      } catch (error) {
        setLoading(false);
        console.error("Error fetching locations:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchLocations();
  }, [dispatch, setLoading]);

  useEffect(() => {
    fetchLegalFirms();
  }, [fetchLegalFirms]);

  // useEffect(() => {
  //   fetchCountries();
  //   fetchLegalFirms();
  // }, [fetchCountries, fetchLegalFirms]);

  // useEffect(() => {
  //   if (selectedCountryId) {
  //     fetchStates(selectedCountryId);
  //   }
  // }, [selectedCountryId, fetchStates]);

  // useEffect(() => {
  //   if (selectedStateId) {
  //     fetchCities(selectedStateId);
  //   }
  // }, [selectedStateId, fetchCities]);

  // useEffect(() => {
  //   if (settings?.country_id) {
  //     setSelectedCountryId(settings.country_id); // Set country based on settings
  //   }
  // }, [settings?.country_id]);
  
  // useEffect(() => {
  //   if (selectedCountryId) {
  //     fetchStates(selectedCountryId);  // Re-fetch states if country changes
  //     setSelectedStateId(settings.state_id);
  //   }
  // }, [selectedCountryId, fetchStates, settings ]);
  
  // useEffect(() => {
  //   if (selectedStateId) {
  //     fetchCities(selectedStateId);  // Re-fetch cities if state changes
  //   }
  // }, [selectedStateId, fetchCities]);

  useEffect(() => {
    const fetchSettings = async () => {
      setLoading(true);
      try {
        await dispatch(getSettings());
      } catch (error) {
        setLoading(false);
        console.error('Error fetching settings:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchSettings();
  }, [dispatch, setLoading]);

  const fetchSettingsData = async () => {
    setLoading(true);
    try {
      await dispatch(getSettings());
    } catch (error) {
      setLoading(false);
      console.error('Error fetching settings:', error);
    } finally {
      setLoading(false);
    }
  }

  const handleSaveCustomerCareData = async (values, { setErrors, setSubmitting }) => {
    try {
      // setLoading(true);
      setIsSubmitting(true);
      setSubmitting(true);
      const res = await dispatch(updateCustomerCareData(values));
      if(res.payload.success === true) {
        setSnackbarMessage(res.payload.message || 'Customer care data updated successfully');
        setSnackbarVariant('success');
        setEditingSection(null);
        setShowSnackbar(true);
        setIsSubmitting(false);
        setSubmitting(false);
        fetchSettingsData();
      } else {
        setErrors(res.payload.data);
        setSnackbarMessage(res.payload.message || 'Failed to update customer care data');
        setSnackbarVariant('danger');
        setShowSnackbar(true);
        setIsSubmitting(false);
        setSubmitting(false);
      }
    } catch (error) {
      setSnackbarMessage('Failed to update customer care data');
      setSnackbarVariant('danger');
      setShowSnackbar(true);
      setIsSubmitting(false);
      setSubmitting(false);
    } finally {
      setIsSubmitting(false);
      setSubmitting(false);
      // setLoading(false);
    }
  };

  const handleSaveBasicInfo = async (values, { setErrors, setSubmitting }) => {
    try {
      // setLoading(true);
      setIsSubmitting(true);
      setSubmitting(true);
      const res = await dispatch(updateBasicInformation(values));
      if(res.payload.success === true) {
        setSnackbarMessage(res.payload.message || 'Basic Information updated successfully');
        setSnackbarVariant('success');
        setEditingSection(null);
        setShowSnackbar(true);
        setIsSubmitting(false);
        setSubmitting(false);
        fetchSettingsData();
      } else {
        setErrors(res.payload.data);
        setSnackbarMessage(res.payload.message || 'Failed to update Basic Information');
        setSnackbarVariant('danger');
        setShowSnackbar(true);
        setIsSubmitting(false);
        setSubmitting(false);
      }
    } catch (error) {
      setSnackbarMessage('Failed to update Basic Information');
      setSnackbarVariant('danger');
      setShowSnackbar(true);
      setIsSubmitting(false);
      setSubmitting(false);
    } finally {
      setIsSubmitting(false);
      setSubmitting(false);
      // setLoading(false);
    }
  };

  const handleSaveSocialLinks = async (values, { setErrors, setSubmitting }) => {
    try {
      // setLoading(true);
      setIsSubmitting(true);
      setSubmitting(true);
      const res = await dispatch(updateSocialLinks(values));
      if(res.payload.success === true) {
        setSnackbarMessage(res.payload.message || 'Social Links updated successfully');
        setSnackbarVariant('success');
        setEditingSection(null);
        setShowSnackbar(true);
        setIsSubmitting(false);
        setSubmitting(false);
        fetchSettingsData();
      } else {
        setErrors(res.payload.data);
        setSnackbarMessage(res.payload.message || 'Failed to update Social Links');
        setSnackbarVariant('danger');
        setShowSnackbar(true);
        setIsSubmitting(false);
        setSubmitting(false);
      }
    } catch (error) {
      setSnackbarMessage('Failed to update Social Links');
      setSnackbarVariant('danger');
      setShowSnackbar(true);
      setIsSubmitting(false);
      setSubmitting(false);
    } finally {
      setIsSubmitting(false);
      setSubmitting(false);
      // setLoading(false);
    }
  };

  const handleSaveIcons = async (values, { setErrors, setSubmitting }) => {
    try {
      // setLoading(true);
      setIsSubmitting(true);
      setSubmitting(true);
      const res = await dispatch(updateIcons(values));
      if(res.payload.success === true) {
        setSnackbarMessage(res.payload.message || 'Icons updated successfully');
        setSnackbarVariant('success');
        setEditingSection(null);
        setShowSnackbar(true);
        setIsSubmitting(false);
        setSubmitting(false);
        fetchSettingsData();
      } else {
        setErrors(res.payload.data);
        setSnackbarMessage(res.payload.message || 'Failed to update icons');
        setSnackbarVariant('danger');
        setShowSnackbar(true);
        setIsSubmitting(false);
        setSubmitting(false);
      }
    } catch (error) {
      setSnackbarMessage('Failed to update icons');
      setSnackbarVariant('danger');
      setShowSnackbar(true);
      setIsSubmitting(false);
      setSubmitting(false);
    } finally {
      setIsSubmitting(false);
      setSubmitting(false);
      // setLoading(false);
    }
  };

  const handleSaveGoogleTag = async (values, { setErrors, setSubmitting }) => {
    try {
      // setLoading(true);
      setIsSubmitting(true);
      setSubmitting(true);
      const res = await dispatch(updateGoogleTag(values));
      if(res.payload.success === true) {
        setSnackbarMessage(res.payload.message || 'Google tag updated successfully');
        setSnackbarVariant('success');
        setEditingSection(null);
        setShowSnackbar(true);
        setIsSubmitting(false);
        setSubmitting(false);
        fetchSettingsData();
      } else {
        setErrors(res.payload.data);
        setSnackbarMessage(res.payload.message || 'Failed to update google tag');
        setSnackbarVariant('danger');
        setShowSnackbar(true);
        setIsSubmitting(false);
        setSubmitting(false);
      }
    } catch (error) {
      setSnackbarMessage('Failed to update google tag');
      setSnackbarVariant('danger');
      setShowSnackbar(true);
      setIsSubmitting(false);
      setSubmitting(false);
    } finally {
      setIsSubmitting(false);
      setSubmitting(false);
      // setLoading(false);
    }
  };

  const handleSaveNewLocation = async (values, { setErrors, setSubmitting }) => {
    try {
      // setLoading(true);
      setIsSubmitting(true);
      setSubmitting(true);
      const res = await dispatch(addLocation(values));
      if(res.payload.success === true) {
        setSnackbarMessage(res.payload.message || 'New Location added successfully');
        setSnackbarVariant('success');
        setEditingSection(null);
        setShowSnackbar(true);
        setIsSubmitting(false);
        setSubmitting(false);
        fetchSettingsData();
        const payload = {
          // search: searchTerm,
        };
        dispatch(getLocations({ payload }));
      } else {
        setErrors(res.payload.data);
        setSnackbarMessage(res.payload.message || 'Failed to add Location');
        setSnackbarVariant('danger');
        setShowSnackbar(true);
        setIsSubmitting(false);
        setSubmitting(false);
      }
    } catch (error) {
      setSnackbarMessage('Failed to add Location');
      setSnackbarVariant('danger');
      setShowSnackbar(true);
      setIsSubmitting(false);
      setSubmitting(false);
    } finally {
      setIsSubmitting(false);
      setSubmitting(false);
      // setLoading(false);
    }
  };

  const handleUpdateLocation = async (values, { setErrors, setSubmitting }) => {
    try {
      // setLoading(true);
      setIsSubmitting(true);
      setSubmitting(true);
      const formData = new FormData();
      formData.append("id", values.id);
      formData.append("_method", "PUT");
      formData.append("branch_name", values.branch_name);
      formData.append("address", values.address);
      formData.append("map_location", values.map_location);
      formData.append("phone_number", values.phone_number);
      formData.append("alt_phone_number", values.alt_phone_number);
      formData.append("email", values.email);
      formData.append("alt_email", values.alt_email);
      formData.append("whatsapp_number", values.whatsapp_number);

      const res = await dispatch(updateLocation(formData));
      if(res.payload.success === true) {
        setSnackbarMessage(res.payload.message || 'Location updated successfully');
        setSnackbarVariant('success');
        setEditingSection(null);
        setShowSnackbar(true);
        setIsSubmitting(false);
        setSubmitting(false);
        fetchSettingsData();
        const payload = {
          // search: searchTerm,
        };
        dispatch(getLocations({ payload }));
      } else {
        setErrors(res.payload.data);
        setSnackbarMessage(res.payload.message || 'Failed to update Location');
        setSnackbarVariant('danger');
        setShowSnackbar(true);
        setIsSubmitting(false);
        setSubmitting(false);
      }
    } catch (error) {
      setSnackbarMessage('Failed to update Location');
      setSnackbarVariant('danger');
      setShowSnackbar(true);
      setIsSubmitting(false);
      setSubmitting(false);
    } finally {
      setIsSubmitting(false);
      setSubmitting(false);
      // setLoading(false);
    }
  };

  // const handleSaveCompanyInfo = async (values, { setErrors, setSubmitting }) => {
  //   try {
  //     // setLoading(true);
  //     setIsSubmitting(true);
  //     setSubmitting(true);
  //     const res = await dispatch(updateCompanyInformation(values));
  //     if(res.payload.success === true) {
  //       setSnackbarMessage(res.payload.message || 'Company Information updated successfully');
  //       setSnackbarVariant('success');
  //       setEditingSection(null);
  //       setSelectedCountryId(null);
  //       setSelectedStateId(null);
  //       setShowSnackbar(true);
  //       setIsSubmitting(false);
  //       setSubmitting(false);
  //       fetchSettingsData();
  //     } else {
  //       setErrors(res.payload.data);
  //       setSnackbarMessage(res.payload.message || 'Failed to update Company Information');
  //       setSnackbarVariant('danger');
  //       setShowSnackbar(true);
  //       setIsSubmitting(false);
  //       setSubmitting(false);
  //     }
  //   } catch (error) {
  //     setSnackbarMessage('Failed to update Company Information');
  //     setSnackbarVariant('danger');
  //     setShowSnackbar(true);
  //     setIsSubmitting(false);
  //     setSubmitting(false);
  //   } finally {
  //     setIsSubmitting(false);
  //     setSubmitting(false);
  //     // setLoading(false);
  //   }
  // };

  // const handleSaveWebsiteConfig = async (values, { setErrors, setSubmitting }) => {
  //   try {
  //     // setLoading(true);
  //     setIsSubmitting(true);
  //     setSubmitting(true);
  //     const res = await dispatch(updateWebsiteConfiguration(values));
  //     if(res.payload.success === true) {
  //       setSnackbarMessage(res.payload.message || 'Website Configuration updated successfully');
  //       setSnackbarVariant('success');
  //       setEditingSection(null);
  //       setShowSnackbar(true);
  //       setIsSubmitting(false);
  //       setSubmitting(false);
  //       fetchSettingsData();
  //     } else {
  //       setErrors(res.payload.data);
  //       setSnackbarMessage(res.payload.message || 'Failed to update Website Configuration');
  //       setSnackbarVariant('danger');
  //       setShowSnackbar(true);
  //       setIsSubmitting(false);
  //       setSubmitting(false);
  //     }
  //   } catch (error) {
  //     setSnackbarMessage('Failed to update Website Configuration');
  //     setSnackbarVariant('danger');
  //     setShowSnackbar(true);
  //     setIsSubmitting(false);
  //     setSubmitting(false);
  //   } finally {
  //     setIsSubmitting(false);
  //     setSubmitting(false);
  //     // setLoading(false);
  //   }
  // };

  const handleEdit = (row) => {
    setSelectedLocationId(row.id);
    setSelectedLocationData(row);
    setEditingSection('location');
  };

  const handleStatusChange = async (id) => {
    setSelectedLocationId(id);
    setShowStatusModal(true);
  }

  const handlePrimaryStatusChangeNew = async () => {
    await dispatch(makePrimaryStatus(selectedLocationId)).then((res) => {
      if(res.payload.success) {
        setShowStatusModal(false);
        setSnackbarMessage(res.payload.message);
        setSnackbarVariant("success");
        setShowSnackbar(true);
        const payload = {
          // search: searchTerm,
        };
        dispatch(getLocations({ payload }));
      } else {
        setShowStatusModal(false);
        setSnackbarMessage(res.payload.message);
        setSnackbarVariant("danger");
        setShowSnackbar(true);
      }
    });
  }

  const handleCancelEdit = () => {
    setEditingSection(null);
    setSelectedLocationId('');
    setSelectedLocationData({});
  };

  if (!settings) return <div>Loading...</div>;

  return (
    <>
        <Modal show={showStatusModal} onHide={() => setShowStatusModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Primary Update</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to make primary this Address/Location?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowStatusModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handlePrimaryStatusChangeNew}>
              Change
            </Button>
          </Modal.Footer>
        </Modal>

      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: 'fixed', top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: 'white' }}>{snackbarMessage}</Toast.Body>
      </Toast>

      <Container fluid className="px-0">
        <Card className="mb-4">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">Basic Information</h5>
            {editingSection !== 'basic' && (
              <CommonButton
                icon={<TaskEdit02Icon width={16} height={16} color="#ffffff" strokeWidth="2" />}
                title="Edit"
                className="btn-primary primary-shadow"
                onClick={() => setEditingSection('basic')}
              />
            )}
          </Card.Header>
          <Card.Body>
            {editingSection === 'basic' ? (
              <Formik
                initialValues={{
                  company_name: settings?.company_name || '',
                  ceo_name: settings?.ceo_name || '',
                  no_of_employees: settings?.no_of_employees || '',
                  year_of_establishment: settings?.year_of_establishment || '',
                  legal_firm_id: settings?.legal_firm_id || '',
                  turn_over: settings?.turn_over || '',
                  gst_number: settings?.gst_number || '',
                  youtube_video_id: settings?.youtube_video_id || '',
                  description: settings?.description || '',

                }}
                validationSchema={basicInformationSchema}
                onSubmit={handleSaveBasicInfo}
              >
                {({ values, handleChange, handleSubmit, touched, errors, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <Row className="gy-4">
                      <Col md={6}>
                        <CommonFormLabel htmlFor="company_name" title="Company Name" />
                        <Input
                          id="company_name"
                          type="text"
                          name="company_name"
                          value={values.company_name}
                          onChange={handleChange}
                          className={`shadow-none ${errors?.company_name && touched.company_name ? 'error' : 'correct'}`}
                        />
                        {touched.company_name && errors?.company_name && (
                          <ValidationFeedback title={errors?.company_name} />
                        )}
                      </Col>
                      <Col md={6}>
                        <CommonFormLabel htmlFor="ceo_name" title="Owner Name" />
                        <Input
                          id="ceo_name"
                          type="text"
                          name="ceo_name"
                          value={values.ceo_name}
                          onChange={handleChange}
                          className={`shadow-none ${errors?.ceo_name && touched.ceo_name ? 'error' : 'correct'}`}
                        />
                        {touched.ceo_name && errors?.ceo_name && (
                          <ValidationFeedback title={errors?.ceo_name} />
                        )}
                      </Col>
                      <Col md={6}>
                        <CommonFormLabel htmlFor="no_of_employees" title="No of Employees" />
                        <Input
                          id="no_of_employees"
                          type="text"
                          name="no_of_employees"
                          value={values.no_of_employees}
                          onChange={handleChange}
                          className={`shadow-none ${errors?.no_of_employees && touched.no_of_employees ? 'error' : 'correct'}`}
                        />
                        {touched.no_of_employees && errors?.no_of_employees && (
                          <ValidationFeedback title={errors?.no_of_employees} />
                        )}
                      </Col>
                      <Col md={6}>
                        <CommonFormLabel htmlFor="year_of_establishment" title="Year of Establishment" />
                        <Input
                          id="year_of_establishment"
                          type="text"
                          name="year_of_establishment"
                          value={values.year_of_establishment}
                          onChange={handleChange}
                          className={`shadow-none ${errors?.year_of_establishment && touched.year_of_establishment ? 'error' : 'correct'}`}
                        />
                        {touched.year_of_establishment && errors?.year_of_establishment && (
                          <ValidationFeedback title={errors?.year_of_establishment} />
                        )}
                      </Col>
                      <Col md={6}>
                        <SimpleDropdown
                          label="Legal Status of Firm"
                          name="legal_firm_id"
                          value={values.legal_firm_id}
                          onChange={(selectedOption) => {
                            setFieldValue("legal_firm_id", selectedOption.value);
                          }}
                          options={legalFirmsOptions}
                          placeholder="Select a Legal Status of Firm"
                          error={errors?.legal_firm_id}
                          touched={touched.legal_firm_id}
                          // className={'mb-0'}
                        />
                        {/* {touched.country_id && errors?.country_id && (
                          <ValidationFeedback title={errors?.country_id} />
                        )} */}
                      </Col>
                      <Col md={6}>
                        <CommonFormLabel htmlFor="turn_over" title="Turn Over" />
                        <Input
                          id="turn_over"
                          type="text"
                          name="turn_over"
                          value={values.turn_over}
                          onChange={handleChange}
                          className={`shadow-none ${errors?.turn_over && touched.turn_over ? 'error' : 'correct'}`}
                        />
                        {touched.turn_over && errors?.turn_over && (
                          <ValidationFeedback title={errors?.turn_over} />
                        )}
                      </Col>
                      <Col md={6}>
                        <CommonFormLabel htmlFor="gst_number" title="GST No." />
                        <Input
                          id="gst_number"
                          type="text"
                          name="gst_number"
                          value={values.gst_number}
                          onChange={handleChange}
                          className={`shadow-none ${errors?.gst_number && touched.gst_number ? 'error' : 'correct'}`}
                        />
                        {touched.gst_number && errors?.gst_number && (
                          <ValidationFeedback title={errors?.gst_number} />
                        )}
                      </Col>
                      <Col md={6}>
                        <CommonFormLabel htmlFor="youtube_video_id" title="Common Youtube Link" />
                        <Input
                          id="youtube_video_id"
                          type="text"
                          name="youtube_video_id"
                          value={values.youtube_video_id}
                          onChange={handleChange}
                          className={`shadow-none ${errors?.youtube_video_id && touched.youtube_video_id ? 'error' : 'correct'}`}
                        />
                        {touched.youtube_video_id && errors?.youtube_video_id && (
                          <ValidationFeedback title={errors?.youtube_video_id} />
                        )}
                      </Col>
                      <Col md={12}>
                        <CommonFormLabel htmlFor="description" title="Company Description" />
                        <Textarea
                            id="description"
                            name="description"
                            placeholder="Enter Company description"
                            onChange={handleChange}
                            value={values.description}
                            className={`shadow-none ${errors?.description && touched.description ? 'error' : 'correct'}`}
                            rows={6}
                        />
                        {touched.description && errors?.description && (
                          <ValidationFeedback title={errors?.description} />
                        )}
                      </Col>
                      <Col md={12} className="d-flex justify-content-end gap-3 mt-4">
                        <CommonButton
                          title={isSubmitting ? "Updating..." : "Update"}
                          className="btn-primary primary-shadow"
                          disabled={isSubmitting} 
                          type="submit"
                        />
                        <CommonButton
                          title="Cancel"
                          className="btn-muted"
                          disabled={isSubmitting} 
                          type="button"
                          onClick={handleCancelEdit}
                        />
                      </Col>
                    </Row>
                  </form>
                )}
              </Formik>
            ) : (
              <Row className="gy-4">
                <Col md={6}>
                  <CommonFormLabel htmlFor="company_name" title="Company Name" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.company_name}</p>
                </Col>
                <Col md={6}>
                  <CommonFormLabel htmlFor="ceo_name" title="Owner Name" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.ceo_name}</p>
                </Col>
                <Col md={6}>
                  <CommonFormLabel htmlFor="no_of_employees" title="No of Employees" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.no_of_employees}</p>
                </Col>
                <Col md={6}>
                  <CommonFormLabel htmlFor="year_of_establishment" title="Year of Establishment" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.year_of_establishment}</p>
                </Col>
                <Col md={6}>
                  <CommonFormLabel htmlFor="legal_firm" title="Legal Status of Firm" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.legal_firm?.name}</p>
                </Col>
                <Col md={6}>
                  <CommonFormLabel htmlFor="turn_over" title="Turn Over" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.turn_over}</p>
                </Col>
                <Col md={6}>
                  <CommonFormLabel htmlFor="gst_number" title="GST No." style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.gst_number}</p>
                </Col>
                <Col md={6}>
                  <CommonFormLabel htmlFor="youtube_video_id" title="Common Youtube Link" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.youtube_video_id}</p>
                </Col>
                <Col md={6}>
                  <CommonFormLabel htmlFor="business_type" title="Business Type" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.business_type?.name}</p>
                </Col>
                <Col md={6}>
                  <CommonFormLabel htmlFor="organization_type" title="Organization Type" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.organization_type?.name}</p>
                </Col>
                <Col md={12}>
                  <CommonFormLabel htmlFor="description" title="Company Description" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.description}</p>
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">Customer Care</h5>
            {editingSection !== 'customer-care' && (
              <CommonButton
                icon={<TaskEdit02Icon width={16} height={16} color="#ffffff" strokeWidth="2" />}
                title="Edit"
                className="btn-primary primary-shadow"
                onClick={() => setEditingSection('customer-care')}
              />
            )}
          </Card.Header>
          <Card.Body>
            {editingSection === 'customer-care' ? (
              <Formik
                initialValues={{
                  customer_care_no: settings?.customer_care_no || '',
                }}
                validationSchema={customerCareSchema}
                onSubmit={handleSaveCustomerCareData}
              >
                {({ values, handleChange, handleSubmit, touched, errors, setFieldValue }) => (
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <Row className="gy-4">
                      <Col md={4}>
                        <CommonFormLabel htmlFor="customer_care_no" title="Customer Care No" />
                        <Input
                          id="customer_care_no"
                          type="text"
                          name="customer_care_no"
                          value={values.customer_care_no}
                          onChange={handleChange}
                          className={`shadow-none ${errors?.customer_care_no && touched.customer_care_no ? 'error' : 'correct'}`}
                        />
                        {touched.customer_care_no && errors?.customer_care_no && (
                          <ValidationFeedback title={errors?.customer_care_no} />
                        )}
                      </Col>
                      <Col md={12} className="d-flex justify-content-end gap-3 mt-4">
                        <CommonButton
                          title={isSubmitting ? "Updating..." : "Update"}
                          className="btn-primary primary-shadow"
                          disabled={isSubmitting} 
                          type="submit"
                        />
                        <CommonButton
                          title="Cancel"
                          className="btn-muted"
                          disabled={isSubmitting} 
                          type="button"
                          onClick={handleCancelEdit}
                        />
                      </Col>
                    </Row>
                  </form>
                )}
              </Formik>
            ) : (
              <Row className="gy-4">
                <Col md={6}>
                  <CommonFormLabel htmlFor="customer_care_no" title="Customer Care Number" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.customer_care_no}</p>
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">Social Links</h5>
            {editingSection !== 'social' && (
              <CommonButton
                icon={<TaskEdit02Icon width={16} height={16} color="#ffffff" strokeWidth="2" />}
                title="Edit"
                className="btn-primary primary-shadow"
                onClick={() => setEditingSection('social')}
              />
            )}
          </Card.Header>
          <Card.Body>
            {editingSection === 'social' ? (
              <Formik
                initialValues={{
                  facebook_link: settings?.facebook_link || '',
                  instagram_link: settings?.instagram_link || '',
                  twitter_link: settings?.twitter_link || '',
                  pinterest_link: settings?.pinterest_link || '',
                  linkedin_link: settings?.linkedin_link || '',
                  youtube_link: settings?.youtube_link || '',
                }}
                validationSchema={socialLinksSchema}
                onSubmit={handleSaveSocialLinks}
              >
                {({ values, handleChange, handleSubmit, touched, errors }) => (
                  <form onSubmit={handleSubmit}>
                    <Row className="gy-4">
                      <Col md={6}>
                        <CommonFormLabel htmlFor="facebook_link" title="Facebook Link" />
                        <Input
                          id="facebook_link"
                          name="facebook_link"
                          type="text"
                          placeholder="Enter Facebook Link"
                          onChange={handleChange}
                          value={values.facebook_link}
                          className={`shadow-none ${errors?.facebook_link && touched.facebook_link ? 'error' : 'correct'}`}
                        />
                        {touched.facebook_link && errors?.facebook_link && (
                          <ValidationFeedback title={errors?.facebook_link} />
                        )}
                      </Col>
                      <Col md={6}>
                      <CommonFormLabel htmlFor="instagram_link" title="Instagram Link" />
                        <Input
                          id="instagram_link"
                          name="instagram_link"
                          type="text"
                          onChange={handleChange}
                          value={values.instagram_link}
                          className={`shadow-none ${errors?.instagram_link && touched.instagram_link ? 'error' : 'correct'}`}
                        />
                        {touched.instagram_link && errors?.instagram_link && (
                          <ValidationFeedback title={errors?.instagram_link} />
                        )}
                      </Col>
                      <Col md={6}>
                        <CommonFormLabel htmlFor="twitter_link" title="Twitter Link" />
                        <Input
                          id="twitter_link"
                          name="twitter_link"
                          type="text"
                          onChange={handleChange}
                          value={values.twitter_link}
                          className={`shadow-none ${errors?.twitter_link && touched.twitter_link ? 'error' : 'correct'}`}
                        />
                        {touched.twitter_link && errors?.twitter_link && (
                          <ValidationFeedback title={errors?.twitter_link} />
                        )}
                      </Col>
                      <Col md={6}>
                        <CommonFormLabel htmlFor="pinterest_link" title="Pinterest Link" />
                        <Input
                          id="pinterest_link"
                          name="pinterest_link"
                          type="text"
                          onChange={handleChange}
                          value={values.pinterest_link}
                          className={`shadow-none ${errors?.pinterest_link && touched.pinterest_link ? 'error' : 'correct'}`}
                        />
                        {touched.pinterest_link && errors?.pinterest_link && (
                          <ValidationFeedback title={errors?.pinterest_link} />
                        )}
                      </Col>
                      <Col md={6}>
                        <CommonFormLabel htmlFor="linkedin_link" title="LinkedIn Link" />
                        <Input
                          id="linkedin_link"
                          name="linkedin_link"
                          type="text"
                          onChange={handleChange}
                          value={values.linkedin_link}
                          className={`shadow-none ${errors?.linkedin_link && touched.linkedin_link ? 'error' : 'correct'}`}
                        />
                        {touched.linkedin_link && errors?.linkedin_link && (
                          <ValidationFeedback title={errors?.linkedin_link} />
                        )}
                      </Col>
                      <Col md={6}>
                        <CommonFormLabel htmlFor="youtube_link" title="YouTube Link" />
                        <Input
                          id="youtube_link"
                          name="youtube_link"
                          type="text"
                          onChange={handleChange}
                          value={values.youtube_link}
                          className={`shadow-none ${errors?.youtube_link && touched.youtube_link ? 'error' : 'correct'}`}
                        />
                        {touched.youtube_link && errors?.youtube_link && (
                          <ValidationFeedback title={errors?.youtube_link} />
                        )}
                      </Col>
                      <Col md={12} className="d-flex justify-content-end gap-3 mt-4">
                        <CommonButton
                          title={isSubmitting ? "Updating..." : "Update"}
                          disabled={isSubmitting}
                          className="btn-primary primary-shadow"
                          type="submit"
                        />
                        <CommonButton
                          title="Cancel"
                          disabled={isSubmitting} 
                          className="btn-muted"
                          type="button"
                          onClick={handleCancelEdit}
                        />
                      </Col>
                    </Row>
                  </form>
                )}
              </Formik>
            ) : (
              <Row className="gy-4">
                <Col md={6}>
                  <CommonFormLabel htmlFor="facebook_link" title="Facebook Link" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.facebook_link}</p>
                </Col>
                <Col md={6}>
                  <CommonFormLabel htmlFor="instagram_link" title="Instagram Link" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.instagram_link}</p>
                </Col>
                <Col md={6}>
                  <CommonFormLabel htmlFor="twitter_link" title="Twitter Link" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.twitter_link}</p>
                </Col>
                <Col md={6}>
                  <CommonFormLabel htmlFor="pinterest_link" title="Pinterest Link" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.pinterest_link}</p>
                </Col>
                <Col md={6}>
                  <CommonFormLabel htmlFor="linkedin_link" title="Linkedin Link" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.linkedin_link}</p>
                </Col>
                <Col md={6}>
                  <CommonFormLabel htmlFor="youtube_link" title="Youtube Link" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.youtube_link}</p>
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">Website Icons</h5>
            {editingSection !== 'icons' && (
              <CommonButton
                icon={<TaskEdit02Icon width={16} height={16} color="#ffffff" strokeWidth="2" />}
                title="Edit"
                className="btn-primary primary-shadow"
                onClick={() => setEditingSection('icons')}
              />
            )}
          </Card.Header>
          <Card.Body>
            {editingSection === 'icons' ? (
              <Formik
                initialValues={{
                  logo: '',
                  favicon_icon: '',
                }}
                validationSchema={iconsSchema}
                onSubmit={handleSaveIcons}
              >
                {({ values, handleChange, handleSubmit, touched, errors, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <Row className="gy-4">
                    <Col md={6}>
                        <CommonFormLabel htmlFor="logo" title="Upload New Logo" />
                        <FileInput
                          name="logo"
                          onChange={(file) => setFieldValue("logo", file)}
                          error={errors?.logo}
                          touched={touched.logo}
                        />
                        {touched.logo && errors?.logo && (
                          <ValidationFeedback title={errors?.logo} />
                        )}
                      </Col>
                      <Col md={6}>
                        <CommonFormLabel htmlFor="favicon_icon" title="Favicon Icon" />
                        <FileInput
                          name="favicon_icon"
                          onChange={(file) => setFieldValue("favicon_icon", file)}
                          error={errors?.favicon_icon}
                          touched={touched.favicon_icon}
                        />
                        {touched.favicon_icon && errors?.favicon_icon && (
                          <ValidationFeedback title={errors?.favicon_icon} />
                        )}
                      </Col>
                      <Col md={12} className="d-flex justify-content-end gap-3 mt-4">
                        <CommonButton
                          title={isSubmitting ? "Updating..." : "Update"}
                          className="btn-primary primary-shadow"
                          disabled={isSubmitting} 
                          type="submit"
                        />
                        <CommonButton
                          title="Cancel"
                          className="btn-muted"
                          disabled={isSubmitting} 
                          type="button"
                          onClick={handleCancelEdit}
                        />
                      </Col>
                    </Row>
                  </form>
                )}
              </Formik>
            ) : (
              <Row className="gy-4">
                <Col md={6} className="">
                  <CommonFormLabel htmlFor="logo" title="Uploaded Image" />
                  <div className="mt-2">
                    <img src={settings.logo} alt="Logo" className="img-fluid" height={"150px"} width={"150px"} />
                  </div>
                </Col>
                <Col md={6} className="">
                  <CommonFormLabel htmlFor="favicon_icon" title="Uploaded Favicon" />
                  <div className="mt-2">
                    <img src={settings.favicon_icon} alt="Favicon" className="img-fluid" height={"150px"} width={"150px"} />
                  </div>
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">Google Analytics Tag</h5>
            {editingSection !== 'google' && (
              <CommonButton
                icon={<TaskEdit02Icon width={16} height={16} color="#ffffff" strokeWidth="2" />}
                title="Edit"
                className="btn-primary primary-shadow"
                onClick={() => setEditingSection('google')}
              />
            )}
          </Card.Header>
          <Card.Body>
            {editingSection === 'google' ? (
              <Formik
                initialValues={{
                  gtag: settings?.gtag || '',
                }}
                validationSchema={googleTagSchema}
                onSubmit={handleSaveGoogleTag}
              >
                {({ values, handleChange, handleSubmit, touched, errors, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <Row className="gy-4">
                      <Col md={12}>
                        <CommonFormLabel htmlFor="gtag" title="Google Tag" />
                        <Textarea
                          id="gtag"
                          name="gtag"
                          placeholder="Enter Google Tag"
                          onChange={handleChange}
                          value={values.gtag}
                          className={`shadow-none ${errors?.gtag && touched.gtag ? 'error' : 'correct'}`}
                          rows={12}
                      />
                        {touched.gtag && errors?.gtag && (
                          <ValidationFeedback title={errors?.gtag} />
                        )}
                      </Col>
                      <Col md={12} className="d-flex justify-content-end gap-3 mt-4">
                        <CommonButton
                          title={isSubmitting ? "Updating..." : "Update"}
                          className="btn-primary primary-shadow"
                          disabled={isSubmitting} 
                          type="submit"
                        />
                        <CommonButton
                          title="Cancel"
                          className="btn-muted"
                          disabled={isSubmitting} 
                          type="button"
                          onClick={handleCancelEdit}
                        />
                      </Col>
                    </Row>
                  </form>
                )}
              </Formik>
            ) : (
              <Row className="gy-4">
                <Col md={12}>
                  <CommonFormLabel htmlFor="gtag" title="Google Tag" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p className='mt-4 mx-4'>{settings?.gtag}</p>
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <div className='d-flex justify-content-between align-items-center'>
              <h5 className="my-2">Branches / Locations</h5>
              <CommonFormLabel className={'ms-4 mt-2'} htmlFor="note" title="(Note: We will user 'Primary' contact detail to show Contact Us page in our website)" style={{ fontSize: '16px' }} />
            </div>
            {editingSection !== 'location' && (
              <CommonButton
                icon={<TaskEdit02Icon width={16} height={28} color="#ffffff" strokeWidth="2" />}
                title="Add New"
                className="btn-primary primary-shadow"
                onClick={() => setEditingSection('location')}
              />
            )}
          </Card.Header>
          <Card.Body>
            {editingSection === 'location' ? (
              <>
              { selectedLocationId ? (
                <>
                  <Formik
                    initialValues={{
                    branch_name : selectedLocationData?.branch_name || "",
                    address : selectedLocationData?.address || "",
                    map_location : selectedLocationData?.map_location || "",
                    phone_number : selectedLocationData?.phone_number || "",
                    alt_phone_number : selectedLocationData?.alt_phone_number || "",
                    email : selectedLocationData?.email || "",
                    alt_email : selectedLocationData?.alt_email || "",
                    whatsapp_number : selectedLocationData?.whatsapp_number || "",
                    id : selectedLocationData?.id || "",
                    }}
                    validationSchema={locationUpdateSchema}
                    onSubmit={handleUpdateLocation}
                  >
                    {({ values, handleChange, handleSubmit, touched, errors, setFieldValue }) => (
                      <form onSubmit={handleSubmit}>
                        <Row className="gy-4">
                          <Col md={6}>
                            <CommonFormLabel htmlFor="branch_name" title="Branch Name" />
                            <Input
                              id="branch_name"
                              type="text"
                              name="branch_name"
                              value={values.branch_name}
                              onChange={handleChange}
                              className={`shadow-none ${errors?.branch_name && touched.branch_name ? 'error' : 'correct'}`}
                            />
                            {touched.branch_name && errors?.branch_name && (
                              <ValidationFeedback title={errors?.branch_name} />
                            )}
                          </Col>

                          <Col md={6}>
                            <CommonFormLabel htmlFor="phone_number" title="Phone Number" />
                            <Input
                              id="phone_number"
                              type="text"
                              name="phone_number"
                              value={values.phone_number}
                              onChange={handleChange}
                              className={`shadow-none ${errors?.phone_number && touched.phone_number ? 'error' : 'correct'}`}
                            />
                            {touched.phone_number && errors?.phone_number && (
                              <ValidationFeedback title={errors?.phone_number} />
                            )}
                          </Col>

                          <Col md={6}>
                            <CommonFormLabel htmlFor="alt_phone_number" title="Alt Phone Number" />
                            <Input
                              id="alt_phone_number"
                              type="text"
                              name="alt_phone_number"
                              value={values.alt_phone_number}
                              onChange={handleChange}
                              className={`shadow-none ${errors?.alt_phone_number && touched.alt_phone_number ? 'error' : 'correct'}`}
                            />
                            {touched.alt_phone_number && errors?.alt_phone_number && (
                              <ValidationFeedback title={errors?.alt_phone_number} />
                            )}
                          </Col>

                          <Col md={6}>
                            <CommonFormLabel htmlFor="whatsapp_number" title="Whatsapp Number" />
                            <Input
                              id="whatsapp_number"
                              type="text"
                              name="whatsapp_number"
                              value={values.whatsapp_number}
                              onChange={handleChange}
                              className={`shadow-none ${errors?.whatsapp_number && touched.whatsapp_number ? 'error' : 'correct'}`}
                            />
                            {touched.whatsapp_number && errors?.whatsapp_number && (
                              <ValidationFeedback title={errors?.whatsapp_number} />
                            )}
                          </Col>

                          <Col md={6}>
                          <CommonFormLabel htmlFor="email" title="Email" />
                          <Input
                            id="email"
                            type="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            className={`shadow-none ${errors?.email && touched.email ? 'error' : 'correct'}`}
                          />
                          {touched.email && errors?.email && (
                            <ValidationFeedback title={errors?.email} />
                          )}
                        </Col>

                        <Col md={6}>
                          <CommonFormLabel htmlFor="alt_email" title="Alt Email" />
                          <Input
                            id="alt_email"
                            type="email"
                            name="alt_email"
                            value={values.alt_email}
                            onChange={handleChange}
                            className={`shadow-none ${errors?.alt_email && touched.alt_email ? 'error' : 'correct'}`}
                          />
                          {touched.alt_email && errors?.alt_email && (
                            <ValidationFeedback title={errors?.alt_email} />
                          )}
                        </Col>

                        <Col md={12} className="mb-4">
                          <CommonFormLabel htmlFor="address" title="Address" />
                          <Textarea
                              id="address"
                              name="address"
                              placeholder="Enter Address"
                              onChange={handleChange}
                              value={values.address}
                              className={`shadow-none ${errors?.address && touched.address ? 'error' : 'correct'}`}
                              rows={6}
                          />
                          {touched.address && errors?.address && (
                              <ValidationFeedback title={errors?.address} />
                          )}
                        </Col>

                        <Col md={12} className="mb-4">
                          <CommonFormLabel htmlFor="map_location" title="Map Location" />
                          <Textarea
                              id="map_location"
                              name="map_location"
                              placeholder="Enter Map Location"
                              onChange={handleChange}
                              value={values.map_location}
                              className={`shadow-none ${errors?.map_location && touched.map_location ? 'error' : 'correct'}`}
                              rows={6}
                          />
                          {touched.map_location && errors?.map_location && (
                              <ValidationFeedback title={errors?.map_location} />
                          )}
                        </Col>


                          <Col md={12} className="d-flex justify-content-end gap-3 mt-4">
                            <CommonButton
                              title={isSubmitting ? "Updating..." : "Update"}
                              className="btn-primary primary-shadow"
                              disabled={isSubmitting} 
                              type="submit"
                            />
                            <CommonButton
                              title="Cancel"
                              className="btn-muted"
                              disabled={isSubmitting} 
                              type="button"
                              onClick={handleCancelEdit}
                            />
                          </Col>
                        </Row>
                      </form>
                    )}
                  </Formik>
                </>
              ) : (
                <Formik
                  initialValues={{
                    branch_name : "",
                    address : "",
                    map_location : "",
                    phone_number : "",
                    alt_phone_number : "",
                    email : "",
                    alt_email : "",
                    whatsapp_number : "",
                  }}
                  validationSchema={locationSchema}
                  onSubmit={handleSaveNewLocation}
                >
                  {({ values, handleChange, handleSubmit, touched, errors, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                      <Row className="gy-4">
                        <Col md={6}>
                          <CommonFormLabel htmlFor="branch_name" title="Branch Name" />
                          <Input
                            id="branch_name"
                            type="text"
                            name="branch_name"
                            value={values.branch_name}
                            onChange={handleChange}
                            className={`shadow-none ${errors?.branch_name && touched.branch_name ? 'error' : 'correct'}`}
                          />
                          {touched.branch_name && errors?.branch_name && (
                            <ValidationFeedback title={errors?.branch_name} />
                          )}
                        </Col>

                        <Col md={6}>
                          <CommonFormLabel htmlFor="phone_number" title="Phone Number" />
                          <Input
                            id="phone_number"
                            type="text"
                            name="phone_number"
                            value={values.phone_number}
                            onChange={handleChange}
                            className={`shadow-none ${errors?.phone_number && touched.phone_number ? 'error' : 'correct'}`}
                          />
                          {touched.phone_number && errors?.phone_number && (
                            <ValidationFeedback title={errors?.phone_number} />
                          )}
                        </Col>

                        <Col md={6}>
                          <CommonFormLabel htmlFor="alt_phone_number" title="Alt Phone Number" />
                          <Input
                            id="alt_phone_number"
                            type="text"
                            name="alt_phone_number"
                            value={values.alt_phone_number}
                            onChange={handleChange}
                            className={`shadow-none ${errors?.alt_phone_number && touched.alt_phone_number ? 'error' : 'correct'}`}
                          />
                          {touched.alt_phone_number && errors?.alt_phone_number && (
                            <ValidationFeedback title={errors?.alt_phone_number} />
                          )}
                        </Col>

                        <Col md={6}>
                          <CommonFormLabel htmlFor="whatsapp_number" title="Whatsapp Number" />
                          <Input
                            id="whatsapp_number"
                            type="text"
                            name="whatsapp_number"
                            value={values.whatsapp_number}
                            onChange={handleChange}
                            className={`shadow-none ${errors?.whatsapp_number && touched.whatsapp_number ? 'error' : 'correct'}`}
                          />
                          {touched.whatsapp_number && errors?.whatsapp_number && (
                            <ValidationFeedback title={errors?.whatsapp_number} />
                          )}
                        </Col>

                        <Col md={6}>
                        <CommonFormLabel htmlFor="email" title="Email" />
                        <Input
                          id="email"
                          type="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          className={`shadow-none ${errors?.email && touched.email ? 'error' : 'correct'}`}
                        />
                        {touched.email && errors?.email && (
                          <ValidationFeedback title={errors?.email} />
                        )}
                      </Col>

                      <Col md={6}>
                        <CommonFormLabel htmlFor="alt_email" title="Alt Email" />
                        <Input
                          id="alt_email"
                          type="email"
                          name="alt_email"
                          value={values.alt_email}
                          onChange={handleChange}
                          className={`shadow-none ${errors?.alt_email && touched.alt_email ? 'error' : 'correct'}`}
                        />
                        {touched.alt_email && errors?.alt_email && (
                          <ValidationFeedback title={errors?.alt_email} />
                        )}
                      </Col>

                      <Col md={12} className="mb-4">
                        <CommonFormLabel htmlFor="address" title="Address" />
                        <Textarea
                            id="address"
                            name="address"
                            placeholder="Enter Address"
                            onChange={handleChange}
                            value={values.address}
                            className={`shadow-none ${errors?.address && touched.address ? 'error' : 'correct'}`}
                            rows={6}
                        />
                        {touched.address && errors?.address && (
                            <ValidationFeedback title={errors?.address} />
                        )}
                      </Col>

                      <Col md={12} className="mb-4">
                        <CommonFormLabel htmlFor="map_location" title="Map Location" />
                        <Textarea
                            id="map_location"
                            name="map_location"
                            placeholder="Enter Map Location"
                            onChange={handleChange}
                            value={values.map_location}
                            className={`shadow-none ${errors?.map_location && touched.map_location ? 'error' : 'correct'}`}
                            rows={6}
                        />
                        {touched.map_location && errors?.map_location && (
                            <ValidationFeedback title={errors?.map_location} />
                        )}
                      </Col>


                        <Col md={12} className="d-flex justify-content-end gap-3 mt-4">
                          <CommonButton
                            title={isSubmitting ? "Adding..." : "Add"}
                            className="btn-primary primary-shadow"
                            disabled={isSubmitting} 
                            type="submit"
                          />
                          <CommonButton
                            title="Cancel"
                            className="btn-muted"
                            disabled={isSubmitting} 
                            type="button"
                            onClick={handleCancelEdit}
                          />
                        </Col>
                      </Row>
                    </form>
                  )}
                </Formik>
              )}
              </>
            ) : (
                <div className="bg-white rounded-2 overflow-hidden common-shadow">
                  { locations && locations?.data?.length > 0 ? (
                    <CommonTable
                      columns={locationColumns}
                      data={locations?.data || []}
                      showActions={true}
                      actions={{
                        showEdit: true,
                        showDelete: false,
                        showView: false,
                        onEdit: handleEdit,
                        // onDelete: handleDelete,
                        // onView: handleView
                      }}
                      onStatusChange={handleStatusChange}
                    />
                  ) : (
                    <>
                      <div className="bg-white rounded-2 overflow-hidden common-shadow">
                        <h3 className="text-center my-5">No Locations Found</h3>
                      </div>
                    </>

                  )}
                </div>
            )}
          </Card.Body>
        </Card>

        {/* <Card className="mb-4">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">Company Information</h5>
            {editingSection !== 'company' && (
              <CommonButton
                icon={<TaskEdit02Icon width={16} height={16} color="#ffffff" strokeWidth="2" />}
                title="Edit"
                className="btn-primary primary-shadow"
                onClick={() => setEditingSection('company')}
              />
            )}
          </Card.Header>
          <Card.Body>
            {editingSection === 'company' ? (
              <Formik
                initialValues={{
                  company_name: settings?.company_name || '',
                  ceo_name: settings?.ceo_name || '',
                  description: settings?.description || '',
                  company_address: settings?.company_address || '',
                  pin_code: settings?.pin_code || '',
                  country_id: settings?.country_id || '',
                  state_id: settings?.state_id || '',
                  city_id: settings?.city_id || '',
                  no_of_employees: settings?.no_of_employees || '',
                  year_of_establishment: settings?.year_of_establishment || '',
                  legal_status_of_firm_id: settings?.legal_status_of_firm_id || '',
                  turn_over: settings?.turn_over || '',
                  youtube_video_id: settings?.youtube_video_id || '',
                }}
                validationSchema={companyInformationSchema}
                onSubmit={handleSaveCompanyInfo}
              >
                {({ values, handleChange, handleSubmit, touched, errors, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <Row className="gy-4">
                      <Col md={6}>
                        <CommonFormLabel htmlFor="company_name" title="Company Name" />
                        <Input
                          id="company_name"
                          type="text"
                          name="company_name"
                          value={values.company_name}
                          onChange={handleChange}
                          className={`shadow-none ${errors?.company_name && touched.company_name ? 'error' : 'correct'}`}
                        />
                        {touched.company_name && errors?.company_name && (
                          <ValidationFeedback title={errors?.company_name} />
                        )}
                      </Col>
                      <Col md={6}>
                        <CommonFormLabel htmlFor="ceo_name" title="CEO Name" />
                        <Input
                          id="ceo_name"
                          type="text"
                          name="ceo_name"
                          value={values.ceo_name}
                          onChange={handleChange}
                          className={`shadow-none ${errors?.ceo_name && touched.ceo_name ? 'error' : 'correct'}`}
                        />
                        {touched.ceo_name && errors?.ceo_name && (
                          <ValidationFeedback title={errors?.ceo_name} />
                        )}
                      </Col>
                      
                      <Col md={6}>
                        <CommonFormLabel htmlFor="pin_code" title="Pin Code" />
                        <Input
                          id="pin_code"
                          type="text"
                          name="pin_code"
                          value={values.pin_code}
                          onChange={handleChange}
                          className={`shadow-none ${errors?.pin_code && touched.pin_code ? 'error' : 'correct'}`}
                        />
                        {touched.pin_code && errors?.pin_code && (
                          <ValidationFeedback title={errors?.pin_code} />
                        )}
                      </Col>
                      <Col md={6}>
                        <SimpleDropdown
                          label="Country"
                          name="country_id"
                          value={values.country_id}
                          onChange={(selectedOption) => {
                            setFieldValue("country_id", selectedOption.value);
                            setFieldValue("state_id", "");
                            setFieldValue("city_id", "");
                            setSelectedCountryId(selectedOption.value);
                          }}
                          options={countriesOptions}
                          placeholder="Select a Country"
                          error={errors?.country_id}
                          touched={touched.country_id}
                        />
                      </Col>
                      <Col md={6}>
                        <SimpleDropdown
                          label="State"
                          name="state_id"
                          value={values.state_id}
                          onChange={(selectedOption) => {
                            setFieldValue("state_id", selectedOption.value);
                            setFieldValue("city_id", "");
                            setSelectedStateId(selectedOption.value);
                          }}
                          options={statesOptions}
                          placeholder="Select a State"
                          error={errors?.state_id}
                          touched={touched.state_id}
                        />
                      </Col>
                      <Col md={6}>
                        <SimpleDropdown
                          label="City"
                          name="city_id"
                          value={values.city_id}
                          onChange={(selectedOption) => {
                            setFieldValue("city_id", selectedOption.value);
                            setSelectedStateId(selectedOption.value);
                          }}
                          options={citiesOptions}
                          placeholder="Select a City"
                          error={errors?.city_id}
                          touched={touched.city_id}
                        />
                      </Col>
                      
                      <Col md={6}>
                        <CommonFormLabel htmlFor="no_of_employees" title="No of Employees" />
                        <Input
                          id="no_of_employees"
                          type="text"
                          name="no_of_employees"
                          value={values.no_of_employees}
                          onChange={handleChange}
                          className={`shadow-none ${errors?.no_of_employees && touched.no_of_employees ? 'error' : 'correct'}`}
                        />
                        {touched.no_of_employees && errors?.no_of_employees && (
                          <ValidationFeedback title={errors?.no_of_employees} />
                        )}
                      </Col>
                      <Col md={6}>
                        <CommonFormLabel htmlFor="year_of_establishment" title="Year of Establishment" />
                        <Input
                          id="year_of_establishment"
                          type="text"
                          name="year_of_establishment"
                          value={values.year_of_establishment}
                          onChange={handleChange}
                          className={`shadow-none ${errors?.year_of_establishment && touched.year_of_establishment ? 'error' : 'correct'}`}
                        />
                        {touched.year_of_establishment && errors?.year_of_establishment && (
                          <ValidationFeedback title={errors?.year_of_establishment} />
                        )}
                      </Col>
                      <Col md={6}>
                        <CommonFormLabel htmlFor="legal_status_of_firm_id" title="Legal Status of Firm" />
                        <Input
                          id="legal_status_of_firm_id"
                          type="text"
                          name="legal_status_of_firm_id"
                          value={values.legal_status_of_firm_id}
                          onChange={handleChange}
                          className={`shadow-none ${errors?.legal_status_of_firm_id && touched.legal_status_of_firm_id ? 'error' : 'correct'}`}
                        />
                        {touched.legal_status_of_firm_id && errors?.legal_status_of_firm_id && (
                          <ValidationFeedback title={errors?.legal_status_of_firm_id} />
                        )}
                      </Col>
                      <Col md={6}>
                        <CommonFormLabel htmlFor="turn_over" title="Turn Over" />
                        <Input
                          id="turn_over"
                          type="text"
                          name="turn_over"
                          value={values.turn_over}
                          onChange={handleChange}
                          className={`shadow-none ${errors?.turn_over && touched.turn_over ? 'error' : 'correct'}`}
                        />
                        {touched.turn_over && errors?.turn_over && (
                          <ValidationFeedback title={errors?.turn_over} />
                        )}
                      </Col>
                      <Col md={6}>
                        <CommonFormLabel htmlFor="youtube_video_id" title="Common Youtube Link (optional)" />
                        <Input
                          id="youtube_video_id"
                          type="text"
                          name="youtube_video_id"
                          value={values.youtube_video_id}
                          onChange={handleChange}
                          className={`shadow-none ${errors?.youtube_video_id && touched.youtube_video_id ? 'error' : 'correct'}`}
                        />
                        {touched.youtube_video_id && errors?.youtube_video_id && (
                          <ValidationFeedback title={errors?.youtube_video_id} />
                        )}
                      </Col>
                      <Col md={12} className="mb-4">
                        <CommonFormLabel htmlFor="address" title="Company Address" />
                        <Textarea
                            id="company_address"
                            name="company_address"
                            placeholder="Enter Company Address"
                            onChange={handleChange}
                            value={values.company_address}
                            className={`shadow-none ${errors?.company_address && touched.company_address ? 'error' : 'correct'}`}
                            rows={6}
                        />
                        {touched.company_address && errors?.company_address && (
                            <ValidationFeedback title={errors?.company_address} />
                        )}
                      </Col>
                      
                      <Col md={12}>
                        <CommonFormLabel htmlFor="description" title="Company Description" />
                        <Textarea
                            id="description"
                            name="description"
                            placeholder="Enter Company description"
                            onChange={handleChange}
                            value={values.description}
                            className={`shadow-none ${errors?.description && touched.description ? 'error' : 'correct'}`}
                            rows={6}
                        />
                        {touched.description && errors?.description && (
                          <ValidationFeedback title={errors?.description} />
                        )}
                      </Col>
                      <Col md={12} className="d-flex justify-content-end gap-3 mt-4">
                        <CommonButton
                          title={isSubmitting ? "Updating..." : "Update"}
                          className="btn-primary primary-shadow"
                          disabled={isSubmitting} 
                          type="submit"
                        />
                        <CommonButton
                          title="Cancel"
                          className="btn-muted"
                          disabled={isSubmitting} 
                          type="button"
                          onClick={handleCancelEdit}
                        />
                      </Col>
                    </Row>
                  </form>
                )}
              </Formik>
            ) : (
              <Row className="gy-4">
                <Col md={6}>
                  <CommonFormLabel htmlFor="company_name" title="Company Name" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.company_name}</p>
                </Col>
                <Col md={6}>
                  <CommonFormLabel htmlFor="ceo_name" title="CEO Name" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.ceo_name}</p>
                </Col>
                
                
                <Col md={6}>
                  <CommonFormLabel htmlFor="pin_code" title="Pin Code" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.pin_code}</p>
                </Col>
                <Col md={6}>
                  <CommonFormLabel htmlFor="country" title="Country" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.country?.name}</p>
                </Col>
                <Col md={6}>
                  <CommonFormLabel htmlFor="state" title="State" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.state?.name}</p>
                </Col>
                <Col md={6}>
                  <CommonFormLabel htmlFor="city" title="City" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.city?.name}</p>
                </Col>
                <Col md={6}>
                  <CommonFormLabel htmlFor="no_of_employees" title="No of Employees" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.no_of_employees}</p>
                </Col>
                <Col md={6}>
                  <CommonFormLabel htmlFor="year_of_establishment" title="Year of Establishment" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.year_of_establishment}</p>
                </Col>
                <Col md={6}>
                  <CommonFormLabel htmlFor="legal_status_of_firm_id" title="Legal Status of Firm" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.legal_status_of_firm_id}</p>
                </Col>
                <Col md={6}>
                  <CommonFormLabel htmlFor="turn_over" title="Turn Over" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.turn_over}</p>
                </Col>
                <Col md={6}>
                  <CommonFormLabel htmlFor="youtube_video_id" title="Common Youtube Link" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.youtube_video_id}</p>
                </Col>
                <Col md={12}>
                  <CommonFormLabel htmlFor="company_address" title="Company Address" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.company_address}</p>
                </Col>
                <Col md={12}>
                  <CommonFormLabel htmlFor="description" title="Company Description" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.description}</p>
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card> */}

        {/* <Card className="mb-4">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">Website Configuration</h5>
            {editingSection !== 'website' && (
              <CommonButton
                icon={<TaskEdit02Icon width={16} height={16} color="#ffffff" strokeWidth="2" />}
                title="Edit"
                className="btn-primary primary-shadow"
                onClick={() => setEditingSection('website')}
              />
            )}
          </Card.Header>
          <Card.Body>
            {editingSection === 'website' ? (
              <Formik
                initialValues={{
                  customer_care_no: settings?.customer_care_no || '',
                  logo: '',
                  favicon_icon: '',
                  website_email: settings?.website_email || '',
                  map_location_1: settings?.map_location_1 || '',
                  map_location_2: settings?.map_location_2 || '',
                  gtag: settings?.gtag || '',
                }}
                validationSchema={websiteConfigurationSchema}
                onSubmit={handleSaveWebsiteConfig}
              >
                {({ values, handleChange, handleSubmit, touched, errors, setFieldValue }) => (
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <Row className="gy-4">
                      <Col md={6}>
                        <CommonFormLabel htmlFor="customer_care_no" title="Customer Care No" />
                        <Input
                          id="customer_care_no"
                          type="text"
                          name="customer_care_no"
                          value={values.customer_care_no}
                          onChange={handleChange}
                          className={`shadow-none ${errors?.customer_care_no && touched.customer_care_no ? 'error' : 'correct'}`}
                        />
                        {touched.customer_care_no && errors?.customer_care_no && (
                          <ValidationFeedback title={errors?.customer_care_no} />
                        )}
                      </Col>
                      <Col md={6}>
                        <CommonFormLabel htmlFor="website_email" title="Website Email" />
                        <Input
                          id="website_email"
                          type="email"
                          name="website_email"
                          value={values.website_email}
                          onChange={handleChange}
                          className={`shadow-none ${errors?.website_email && touched.website_email ? 'error' : 'correct'}`}
                        />
                        {touched.website_email && errors?.website_email && (
                          <ValidationFeedback title={errors?.website_email} />
                        )}
                      </Col>
                      
                      <Col md={12}>
                        <CommonFormLabel htmlFor="map_location_1" title="Location 1" />
                        <Textarea
                          id="map_location_1"
                          name="map_location_1"
                          placeholder="Enter Location 1 Value"
                          onChange={handleChange}
                          value={values.map_location_1}
                          className={`shadow-none ${errors?.map_location_1 && touched.map_location_1 ? 'error' : 'correct'}`}
                          rows={6}
                      />
                        {touched.map_location_1 && errors?.map_location_1 && (
                          <ValidationFeedback title={errors?.map_location_1} />
                        )}
                      </Col>
                      <Col md={12}>
                        <CommonFormLabel htmlFor="map_location_2" title="Location 2" />
                        <Textarea
                          id="map_location_2"
                          name="map_location_2"
                          placeholder="Enter Location 2 Value"
                          onChange={handleChange}
                          value={values.map_location_2}
                          className={`shadow-none ${errors?.map_location_2 && touched.map_location_2 ? 'error' : 'correct'}`}
                          rows={6}
                      />
                        {touched.map_location_2 && errors?.map_location_2 && (
                          <ValidationFeedback title={errors?.map_location_2} />
                        )}
                      </Col>
                      <Col md={12}>
                        <CommonFormLabel htmlFor="gtag" title="Google Tag" />
                        <Textarea
                          id="gtag"
                          name="gtag"
                          placeholder="Enter Google Tag"
                          onChange={handleChange}
                          value={values.gtag}
                          className={`shadow-none ${errors?.gtag && touched.gtag ? 'error' : 'correct'}`}
                          rows={6}
                      />
                        {touched.gtag && errors?.gtag && (
                          <ValidationFeedback title={errors?.gtag} />
                        )}
                      </Col>
                      <hr />
                      <Col md={6}>
                        <CommonFormLabel htmlFor="logo" title="Upload New Logo" />
                        <FileInput
                          name="logo"
                          onChange={(file) => setFieldValue("logo", file)}
                          error={errors?.logo}
                          touched={touched.logo}
                        />
                        {touched.logo && errors?.logo && (
                          <ValidationFeedback title={errors?.logo} />
                        )}
                      </Col>
                      <Col md={6}>
                        <CommonFormLabel htmlFor="favicon_icon" title="Favicon Icon" />
                        <FileInput
                          name="favicon_icon"
                          onChange={(file) => setFieldValue("favicon_icon", file)}
                          error={errors?.favicon_icon}
                          touched={touched.favicon_icon}
                        />
                        {touched.favicon_icon && errors?.favicon_icon && (
                          <ValidationFeedback title={errors?.favicon_icon} />
                        )}
                      </Col>
                      <Col md={12} className="d-flex justify-content-end gap-3 mt-4">
                        <CommonButton
                          title={isSubmitting ? "Updating..." : "Update"}
                          className="btn-primary primary-shadow"
                          disabled={isSubmitting} 
                          type="submit"
                        />
                        <CommonButton
                          title="Cancel"
                          className="btn-muted"
                          disabled={isSubmitting} 
                          type="button"
                          onClick={handleCancelEdit}
                        />
                      </Col>
                    </Row>
                  </form>
                )}
              </Formik>
            ) : (
              <Row className="gy-4">
                <Col md={6}>
                  <CommonFormLabel htmlFor="customer_care_no" title="Customer Care Number" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.customer_care_no}</p>
                </Col>
                <Col md={6}>
                  <CommonFormLabel htmlFor="website_email" title="Website Email" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.website_email}</p>
                </Col>
                <Col md={6}>
                  <CommonFormLabel htmlFor="map_location_1" title="Location 1" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.map_location_1}</p>
                </Col>
                <Col md={6}>
                  <CommonFormLabel htmlFor="map_location_2" title="Location 2" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.map_location_2}</p>
                </Col>
                <Col md={12}>
                  <CommonFormLabel htmlFor="gtag" title="Google Tag" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{settings?.gtag}</p>
                </Col>

                <hr />

                <Col md={6} className="">
                  <CommonFormLabel htmlFor="logo" title="Uploaded Image" />
                  <div className="mt-2">
                    <img src={settings.logo} alt="Logo" className="img-fluid" height={"150px"} width={"150px"} />
                  </div>
                </Col>
                <Col md={6} className="">
                  <CommonFormLabel htmlFor="favicon_icon" title="Uploaded Favicon" />
                  <div className="mt-2">
                    <img src={settings.favicon_icon} alt="Favicon" className="img-fluid" height={"150px"} width={"150px"} />
                  </div>
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card> */}


      </Container>
    </>
  );
};

export default SettingList;
