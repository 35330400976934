import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSeoContents, updateSeoContent } from './seoContentSlice';

import { Container, Card, Row, Col, Toast } from 'react-bootstrap';
import Input from "../../components/input";
import Textarea from '../../components/commonTextArea';
import { Formik } from 'formik';
import CommonFormLabel from "../../components/formLabel";
import { metaContentSchema } from '../../schema/validationSchemas';
import ValidationFeedback from "../../components/validationFeedback";
import { TaskEdit02Icon } from '../../assets/icons';
import CommonButton from '../../components/commonButton';
import { useLoader } from '../../context/LoaderContext';

const SettingList = () => {
  const dispatch = useDispatch();
  const { setLoading } = useLoader();
  const { settings } = useSelector((state) => state.settings);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarVariant, setSnackbarVariant] = useState('success');
  const [editingSection, setEditingSection] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [seoContentsHomePage, setSeoContentsHomePage] = useState({});
  const [seoContentsAboutUsPage, setSeoContentsAboutUsPage] = useState({});
  const [seoContentsContactUsPage, setSeoContentsContactUsPage] = useState({});

  useEffect(() => {
    const fetchSeoContentsHomePage = async () => {
      setLoading(true);
      try {
        
        const payload = { slug: "home-page" };
        await dispatch(getSeoContents({ payload })).then((res) => {
            console.log("result", res.payload.data);

            if(res.payload) {
              const seoContents = res.payload.data;
              setSeoContentsHomePage(seoContents);
            }
        });
      } catch (error) {
        setLoading(false);
        console.error('Error fetching seo contents:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchSeoContentsAboutUs = async () => {
        setLoading(true);
        try {
          
          const payload = { slug: "about-us" };
          await dispatch(getSeoContents({ payload })).then((res) => {
  
              if(res.payload) {
                const seoContents = res.payload.data;
                setSeoContentsAboutUsPage(seoContents);
              }
          });
        } catch (error) {
          setLoading(false);
          console.error('Error fetching seo contents:', error);
        } finally {
          setLoading(false);
        }
    };

    const fetchSeoContentsContactUs = async () => {
        setLoading(true);
        try {
          
          const payload = { slug: "contact-us" };
          await dispatch(getSeoContents({ payload })).then((res) => {
  
              if(res.payload) {
                const seoContents = res.payload.data;
                setSeoContentsContactUsPage(seoContents);
              }
          });
        } catch (error) {
          setLoading(false);
          console.error('Error fetching seo contents:', error);
        } finally {
          setLoading(false);
        }
    };
    fetchSeoContentsHomePage();
    fetchSeoContentsAboutUs();
    fetchSeoContentsContactUs();
  }, [dispatch, setLoading]);


  const saveMetaContents = async (values, { setErrors, setSubmitting }) => {
    try {
      // setLoading(true);
      setIsSubmitting(true);
      setSubmitting(true);
      const res = await dispatch(updateSeoContent(values));
      if(res.payload.success === true) {
        setSnackbarMessage(res.payload.message || 'Meta contents updated successfully');
        setSnackbarVariant('success');
        setEditingSection(null);
        setShowSnackbar(true);
        setIsSubmitting(false);
        setSubmitting(false);
        const payload = { slug: values.slug };
        await dispatch(getSeoContents({ payload })).then((res) => {
          if(res.payload) {
            const seoContents = res.payload.data;
            if(values.slug === "home-page") {
              setSeoContentsHomePage(seoContents);
            } else if(values.slug === "about-us") {
              setSeoContentsAboutUsPage(seoContents);
            } else if(values.slug === "contact-us") {
              setSeoContentsContactUsPage(seoContents);
            }
          }
      });
      } else {
        setErrors(res.payload.data);
        setSnackbarMessage(res.payload.message || 'Failed to update Meta contents');
        setSnackbarVariant('danger');
        setShowSnackbar(true);
        setIsSubmitting(false);
        setSubmitting(false);
      }
    } catch (error) {
      setSnackbarMessage('Failed to update Meta contents');
      setSnackbarVariant('danger');
      setShowSnackbar(true);
      setIsSubmitting(false);
      setSubmitting(false);
    } finally {
      setIsSubmitting(false);
      setSubmitting(false);
      // setLoading(false);
    }
  };

  const handleCancelEdit = () => {
    setEditingSection(null);
  };

  if (!settings) return <div>Loading...</div>;

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: 'fixed', top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: 'white' }}>{snackbarMessage}</Toast.Body>
      </Toast>

      <Container fluid className="px-0">
        <Card className="mb-4">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">Home Page Meta Tags</h5>
            {editingSection !== 'home-page' && (
              <CommonButton
                icon={<TaskEdit02Icon width={16} height={16} color="#ffffff" strokeWidth="2" />}
                title="Edit"
                className="btn-primary primary-shadow"
                onClick={() => setEditingSection('home-page')}
              />
            )}
          </Card.Header>
          <Card.Body>
            {editingSection === 'home-page' ? (
              <Formik
                initialValues={{
                    meta_title: seoContentsHomePage?.meta_title || '',
                    meta_keyword: seoContentsHomePage?.meta_keyword || '',
                    meta_description: seoContentsHomePage?.meta_description || '',
                    slug: 'home-page',
                }}
                validationSchema={metaContentSchema}
                onSubmit={saveMetaContents}
              >
                {({ values, handleChange, handleSubmit, touched, errors, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <Row className="gy-4">
                      <Col md={6}>
                        <CommonFormLabel htmlFor="meta_title" title="Meta Title" />
                        <Input
                          id="meta_title"
                          type="text"
                          name="meta_title"
                          value={values.meta_title}
                          onChange={handleChange}
                          className={`shadow-none ${errors?.meta_title && touched.meta_title ? 'error' : 'correct'}`}
                        />
                        {touched.meta_title && errors?.meta_title && (
                          <ValidationFeedback title={errors?.meta_title} />
                        )}
                      </Col>
                      <Col md={12}>
                        <CommonFormLabel htmlFor="meta_description" title="Meta Description" />
                        <Textarea
                            id="meta_description"
                            name="meta_description"
                            placeholder="Enter Meta Description"
                            onChange={handleChange}
                            value={values.meta_description}
                            className={`shadow-none ${errors?.meta_description && touched.meta_description ? 'error' : 'correct'}`}
                            rows={6}
                        />
                        {touched.meta_description && errors?.meta_description && (
                          <ValidationFeedback title={errors?.meta_description} />
                        )}
                      </Col>
                      <Col md={12}>
                        <CommonFormLabel htmlFor="meta_keyword" title="Meta Keywords" />
                        <Textarea
                            id="meta_keyword"
                            name="meta_keyword"
                            placeholder="Enter Meta Keywords"
                            onChange={handleChange}
                            value={values.meta_keyword}
                            className={`shadow-none ${errors?.meta_keyword && touched.meta_keyword ? 'error' : 'correct'}`}
                            rows={6}
                        />
                        {touched.meta_keyword && errors?.meta_keyword && (
                          <ValidationFeedback title={errors?.meta_keyword} />
                        )}
                      </Col>
                      <Col md={12} className="d-flex justify-content-end gap-3 mt-4">
                        <CommonButton
                          title={isSubmitting ? "Updating..." : "Update"}
                          className="btn-primary primary-shadow"
                          disabled={isSubmitting} 
                          type="submit"
                        />
                        <CommonButton
                          title="Cancel"
                          className="btn-muted"
                          disabled={isSubmitting} 
                          type="button"
                          onClick={handleCancelEdit}
                        />
                      </Col>
                    </Row>
                  </form>
                )}
              </Formik>
            ) : (
              <Row className="gy-4">
                <Col md={6}>
                  <CommonFormLabel htmlFor="meta_title" title="Meta Title" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{seoContentsHomePage?.meta_title}</p>
                </Col>
                <Col md={12}>
                  <CommonFormLabel htmlFor="meta_description" title="Meta Description" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{seoContentsHomePage?.meta_description}</p>
                </Col>
                <Col md={12}>
                  <CommonFormLabel htmlFor="meta_keyword" title="Meta Keywords" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{seoContentsHomePage?.meta_keyword}</p>
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">About Us Page Meta Tags</h5>
            {editingSection !== 'about-us' && (
              <CommonButton
                icon={<TaskEdit02Icon width={16} height={16} color="#ffffff" strokeWidth="2" />}
                title="Edit"
                className="btn-primary primary-shadow"
                onClick={() => setEditingSection('about-us')}
              />
            )}
          </Card.Header>
          <Card.Body>
            {editingSection === 'about-us' ? (
              <Formik
                initialValues={{
                    meta_title: seoContentsAboutUsPage?.meta_title || '',
                    meta_keyword: seoContentsAboutUsPage?.meta_keyword || '',
                    meta_description: seoContentsAboutUsPage?.meta_description || '',
                    slug: 'about-us',
                }}
                validationSchema={metaContentSchema}
                onSubmit={saveMetaContents}
              >
                {({ values, handleChange, handleSubmit, touched, errors, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <Row className="gy-4">
                      <Col md={6}>
                        <CommonFormLabel htmlFor="meta_title" title="Meta Title" />
                        <Input
                          id="meta_title"
                          type="text"
                          name="meta_title"
                          value={values.meta_title}
                          onChange={handleChange}
                          className={`shadow-none ${errors?.meta_title && touched.meta_title ? 'error' : 'correct'}`}
                        />
                        {touched.meta_title && errors?.meta_title && (
                          <ValidationFeedback title={errors?.meta_title} />
                        )}
                      </Col>
                      <Col md={12}>
                        <CommonFormLabel htmlFor="meta_description" title="Meta Description" />
                        <Textarea
                            id="meta_description"
                            name="meta_description"
                            placeholder="Enter Meta Description"
                            onChange={handleChange}
                            value={values.meta_description}
                            className={`shadow-none ${errors?.meta_description && touched.meta_description ? 'error' : 'correct'}`}
                            rows={6}
                        />
                        {touched.meta_description && errors?.meta_description && (
                          <ValidationFeedback title={errors?.meta_description} />
                        )}
                      </Col>
                      <Col md={12}>
                        <CommonFormLabel htmlFor="meta_keyword" title="Meta Keywords" />
                        <Textarea
                            id="meta_keyword"
                            name="meta_keyword"
                            placeholder="Enter Meta Keywords"
                            onChange={handleChange}
                            value={values.meta_keyword}
                            className={`shadow-none ${errors?.meta_keyword && touched.meta_keyword ? 'error' : 'correct'}`}
                            rows={6}
                        />
                        {touched.meta_keyword && errors?.meta_keyword && (
                          <ValidationFeedback title={errors?.meta_keyword} />
                        )}
                      </Col>
                      <Col md={12} className="d-flex justify-content-end gap-3 mt-4">
                        <CommonButton
                          title={isSubmitting ? "Updating..." : "Update"}
                          className="btn-primary primary-shadow"
                          disabled={isSubmitting} 
                          type="submit"
                        />
                        <CommonButton
                          title="Cancel"
                          className="btn-muted"
                          disabled={isSubmitting} 
                          type="button"
                          onClick={handleCancelEdit}
                        />
                      </Col>
                    </Row>
                  </form>
                )}
              </Formik>
            ) : (
              <Row className="gy-4">
                <Col md={6}>
                  <CommonFormLabel htmlFor="meta_title" title="Meta Title" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{seoContentsAboutUsPage?.meta_title}</p>
                </Col>
                <Col md={12}>
                  <CommonFormLabel htmlFor="meta_description" title="Meta Description" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{seoContentsAboutUsPage?.meta_description}</p>
                </Col>
                <Col md={12}>
                  <CommonFormLabel htmlFor="meta_keyword" title="Meta Keywords" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{seoContentsAboutUsPage?.meta_keyword}</p>
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">Contact Us Page Meta Tags</h5>
            {editingSection !== 'contact-us' && (
              <CommonButton
                icon={<TaskEdit02Icon width={16} height={16} color="#ffffff" strokeWidth="2" />}
                title="Edit"
                className="btn-primary primary-shadow"
                onClick={() => setEditingSection('contact-us')}
              />
            )}
          </Card.Header>
          <Card.Body>
            {editingSection === 'contact-us' ? (
              <Formik
                initialValues={{
                    meta_title: seoContentsContactUsPage?.meta_title || '',
                    meta_keyword: seoContentsContactUsPage?.meta_keyword || '',
                    meta_description: seoContentsContactUsPage?.meta_description || '',
                    slug: 'contact-us',
                }}
                validationSchema={metaContentSchema}
                onSubmit={saveMetaContents}
              >
                {({ values, handleChange, handleSubmit, touched, errors, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <Row className="gy-4">
                      <Col md={6}>
                        <CommonFormLabel htmlFor="meta_title" title="Meta Title" />
                        <Input
                          id="meta_title"
                          type="text"
                          name="meta_title"
                          value={values.meta_title}
                          onChange={handleChange}
                          className={`shadow-none ${errors?.meta_title && touched.meta_title ? 'error' : 'correct'}`}
                        />
                        {touched.meta_title && errors?.meta_title && (
                          <ValidationFeedback title={errors?.meta_title} />
                        )}
                      </Col>
                      <Col md={12}>
                        <CommonFormLabel htmlFor="meta_description" title="Meta Description" />
                        <Textarea
                            id="meta_description"
                            name="meta_description"
                            placeholder="Enter Meta Description"
                            onChange={handleChange}
                            value={values.meta_description}
                            className={`shadow-none ${errors?.meta_description && touched.meta_description ? 'error' : 'correct'}`}
                            rows={6}
                        />
                        {touched.meta_description && errors?.meta_description && (
                          <ValidationFeedback title={errors?.meta_description} />
                        )}
                      </Col>
                      <Col md={12}>
                        <CommonFormLabel htmlFor="meta_keyword" title="Meta Keywords" />
                        <Textarea
                            id="meta_keyword"
                            name="meta_keyword"
                            placeholder="Enter Meta Keywords"
                            onChange={handleChange}
                            value={values.meta_keyword}
                            className={`shadow-none ${errors?.meta_keyword && touched.meta_keyword ? 'error' : 'correct'}`}
                            rows={6}
                        />
                        {touched.meta_keyword && errors?.meta_keyword && (
                          <ValidationFeedback title={errors?.meta_keyword} />
                        )}
                      </Col>
                      <Col md={12} className="d-flex justify-content-end gap-3 mt-4">
                        <CommonButton
                          title={isSubmitting ? "Updating..." : "Update"}
                          className="btn-primary primary-shadow"
                          disabled={isSubmitting} 
                          type="submit"
                        />
                        <CommonButton
                          title="Cancel"
                          className="btn-muted"
                          disabled={isSubmitting} 
                          type="button"
                          onClick={handleCancelEdit}
                        />
                      </Col>
                    </Row>
                  </form>
                )}
              </Formik>
            ) : (
              <Row className="gy-4">
                <Col md={6}>
                  <CommonFormLabel htmlFor="meta_title" title="Meta Title" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{seoContentsContactUsPage?.meta_title}</p>
                </Col>
                <Col md={12}>
                  <CommonFormLabel htmlFor="meta_description" title="Meta Description" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{seoContentsContactUsPage?.meta_description}</p>
                </Col>
                <Col md={12}>
                  <CommonFormLabel htmlFor="meta_keyword" title="Meta Keywords" style={{ fontWeight: 'bold', fontSize: '16px' }} />
                  <p>{seoContentsContactUsPage?.meta_keyword}</p>
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default SettingList;
