import {
    DashboardSquare02Icon,
    CategoryIcon,
    ProductIcon,
    MarketAreaIcon,
    BlogIcon,
    GalleryIcon,
    TestimonialIcon,
    InquiryIcon,
    TeamIcon,
    SettingIcon,
    HomeIcon,
    AboutUsIcon,
    PrivacyPolicyIcon,
    TermsAndConditionsIcon,
    SEOContentIcon
  } from "../../assets/icons";
  
  export const SidebarContent = [
    {
      title: "Dashboard",
      icon: (
        <DashboardSquare02Icon
          width={18}
          height={18}
          color="#444050"
          strokeWidth="2"
        />
      ),
      link: "/",
    },
    {
      title: "Categories",
      icon: (
        <CategoryIcon width={18} height={18} color="#444050" strokeWidth="2" />
      ),
      link: "/categories",
    },
    // {
    //   title: "Sub Categories",
    //   icon: (
    //     <CategoryIcon width={18} height={18} color="#444050" strokeWidth="2" />
    //   ),
    //   link: "/sub-categories",
    // },
    {
      title: "products",
      icon: (
        <ProductIcon width={18} height={18} color="#444050" strokeWidth="2" />
      ),
      link: "/products",
    },
    {
      title: "blogs",
      icon: (
        <BlogIcon width={18} height={18} color="#444050" strokeWidth="2" />
      ),
      link: "/blogs",
    },
    {
      title: "gallery",
      icon: (
        <GalleryIcon width={18} height={18} color="#444050" strokeWidth="2" />
      ),
      link: "/galleries",
    },
    {
      title: "testimonials",
      icon: (
        <TestimonialIcon width={18} height={18} color="#444050" strokeWidth="2" />
      ),
      link: "/testimonials",
    },
    {
      title: "inquiries",
      icon: (
        <InquiryIcon width={24} height={24} color="#444050" strokeWidth="1.5" />
      ),
      link: "/inquiries",
    },
    {
      title: "Target Regions",
      icon: (
        <MarketAreaIcon width={18} height={18} color="#444050" strokeWidth="2" />
      ),
      link: "/target-regions",
    },
    {
      title: "teams",
      icon: (
        <TeamIcon width={18} height={18} color="#444050" strokeWidth="2" />
      ),
      link: "/teams",
    },
    // {
    //   title: "settings",
    //   icon: (
    //     <SettingIcon width={18} height={18} color="#444050" strokeWidth="2" />
    //   ),
    //   link: "/settings",
    // },
  ];

  export const ConfigurationContent = [
    {
      title: "general",
      icon: (
        <SettingIcon width={18} height={18} color="#444050" strokeWidth="2" />
      ),
      link: "/general",
    },
    {
      title: "Home",
      icon: (
        <HomeIcon width={18} height={18} color="#444050" strokeWidth="2" />
      ),
      link: "/home-page",
    },
    {
      title: "About Us",
      icon: (
        <AboutUsIcon width={18} height={18} color="#444050" strokeWidth="2" />
      ),
      link: "/about-us",
    },
    {
      title: "Privacy Policy",
      icon: (
        <PrivacyPolicyIcon width={18} height={18} color="#444050" strokeWidth="2" />
      ),
      link: "/privacy-policy",
    },
    {
      title: "Terms & Condition",
      icon: (
        <TermsAndConditionsIcon width={18} height={18} color="#444050" strokeWidth="2" />
      ),
      link: "/terms-conditions",
    },
    {
      title: "SEO Configuration",
      icon: (
        <SEOContentIcon width={18} height={18} color="#444050" strokeWidth="2" />
      ),
      link: "/seo-configuration",
    },
  ]
  