import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchSettings, modifySettings, modifySocialLinks, modifyBasicInformation, modifyCompanyInformation, modifyWebsiteConfiguration, fetchCountries, fetchStates, fetchCities, fetchLegalFirms, modifyIcons, modifyCustomerCareData, modifyGoogleTag } from '../../api/settingApi';

export const getSettings = createAsyncThunk('setting/getSettings', async () => {
    try {
        const response = await fetchSettings();
        return response.data.data;
    } catch (error) {
        console.error("Error fetching settings:", error);
    }
});

export const updateSettings = createAsyncThunk('setting/updateSettings', async (payload, { rejectWithValue }) => {
    try {
      const response = await modifySettings(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  });

export const updateSocialLinks = createAsyncThunk('setting/updateSocialLinks', async (payload, { rejectWithValue }) => {
  try {
    const response = await modifySocialLinks(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const updateBasicInformation = createAsyncThunk('setting/updateBasicInformation', async (payload, { rejectWithValue }) => {
  try {
    const response = await modifyBasicInformation(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const updateCompanyInformation = createAsyncThunk('setting/updateCompanyInformation', async (payload, { rejectWithValue }) => {
  try {
    const response = await modifyCompanyInformation(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const updateWebsiteConfiguration = createAsyncThunk('setting/updateWebsiteConfiguration', async (payload, { rejectWithValue }) => {
  try {
    const response = await modifyWebsiteConfiguration(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getCountries = createAsyncThunk('setting/getCountries', async ({payload}, { rejectWithValue }) => {
  try {
      const response = await fetchCountries(payload);
      return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getStates = createAsyncThunk('setting/getStates', async ({payload}, { rejectWithValue }) => {
  try {
      const response = await fetchStates(payload);
      return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getCities = createAsyncThunk('setting/getCities', async ({payload}, { rejectWithValue }) => {
  try {
      const response = await fetchCities(payload);
      return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getLegalFirms = createAsyncThunk('setting/getLegalFirms', async ({payload}, { rejectWithValue }) => {
  try {
      const response = await fetchLegalFirms(payload);
      return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const updateIcons = createAsyncThunk('setting/updateIcons', async (payload, { rejectWithValue }) => {
  try {
    const response = await modifyIcons(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const updateCustomerCareData = createAsyncThunk('setting/updateCustomerCareData', async (payload, { rejectWithValue }) => {
  try {
    const response = await modifyCustomerCareData(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const updateGoogleTag = createAsyncThunk('setting/updateGoogleTag', async (payload, { rejectWithValue }) => {
  try {
    const response = await modifyGoogleTag(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
  
const settingSlice = createSlice({
  name: 'setting',
  initialState: {
    settings: {},
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSettings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.settings = action.payload;
      })
      .addCase(getSettings.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
});

export default settingSlice.reducer;
